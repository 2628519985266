<template>
  <div width="100%">
    <header aria-labelledby=“header-label”>
      <!--link rel="icon" href="/favicon.ico" -->
      <v-container role="none" >
        <v-row justify="center">
          <v-col cols="auto" class="text-center justify-center">
            <a href="https://www.city.yokohama.lg.jp/" 
               title="横浜市トップページ表示"
               style="text-align: center">
                  <v-img justify="center" height="30pt" width="200pt" src="hlogo.png" alt="横浜市ロゴ"></v-img>
            </a>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12" class="text-center justify-center">
            <h1 id="header-label">横浜市コールセンター<br>問合せフォーム</h1>
          </v-col>
        </v-row>
      </v-container>
    </header>

    <!-- ここでページのコンテンツが変わる -->
    <main>
      <router-view />
    </main>
    <footer class="text-center">
      <nav role="navigation">
        <a href="https://www.city.yokohama.lg.jp/faq/" target="_blank">よくある質問</a>
        <router-link to="/accessibility" target="_blank">ウェブアクセシビリティ</router-link>
        <a href="/chat.html" target="_blank">チャット</a>
      </nav>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
}
</script>

<style scoped>
html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

body
{
  font-size: 10pt;
}

main {
  padding-bottom: 50px;
}

h1 {
  font-size: 1.4em;
}

header
{
  background-color: white;
  padding: 10px;
}

footer
{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 10px;
  text-align: center;
}

nav a {
  margin-right: 15px;
}

.v-container
{
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

</style>